import React, { Component } from "react";
import htmlIcon from "../imgs/icons/html.webp";
import cssIcon from "../imgs/icons/css.webp";
import jsIcon from "../imgs/icons/js.webp";
import reactIcon from "../imgs/icons/react.webp";
import nodejsIcon from "../imgs/icons/nodejs.webp";
import expressIcon from "../imgs/icons/express.webp";
import mongodbIcon from "../imgs/icons/mongodb.webp";
import tailwindIcon from "../imgs/icons/tailwind.webp";
import mySqlIcon from "../imgs/icons/mysql.webp";
import gitIcon from "../imgs/icons/git.webp";
import bootstrapIcon from "../imgs/icons/bootstrap.webp";
import materialUiIcon from "../imgs/icons/materialUi.webp";

class Skills extends Component {
  render() {
    return (
      <article className="skills" data-aos="fade-down">
        <div className="skills-text">
          <h1>Skills</h1>
          <p>
            I have been working in the web development industry for
            <span> over a year</span>.
          </p>
        </div>
        <div className="icons">
          <img src={htmlIcon} alt="html" />
          <img src={cssIcon} alt="css" />
          <img src={tailwindIcon} alt="tailwind" />
          <img src={jsIcon} alt="javaScript" />
          <img src={reactIcon} alt="react" />
          <img src={nodejsIcon} alt="nodejs" />
          <img src={expressIcon} alt="express" />
          <img src={mongodbIcon} alt="mongodb" />
          <img src={mySqlIcon} alt="mySql" />
          <img src={gitIcon} alt="git" />
          <img src={bootstrapIcon} alt="bootstrap" />
          <img src={materialUiIcon} alt="materialUi" />
        </div>
      </article>
    );
  }
}

export default Skills;
