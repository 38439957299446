import React, { Component } from "react";
import aboutMeImage from "../imgs/aboutMe.webp";
import Footer from "./Footer";

class AboutMe extends Component {
  render() {
    return (
      <>
        <article className="about-me" data-aos="fade-down">
          <h1>About me</h1>
          <div className="about-me-container">
            <img src={aboutMeImage} alt="User with Mac Icon" />
            <div className="about-me-text">
              <h2>Software engineer</h2>
              <p>
                Hi, I'm Abdessamad Idboussadel, a 21-year-old full stack web
                developer. I am currently studying at Ensa Agadir. I'm
                passionate about developing and optimizing interactive,
                user-friendly, and feature-rich websites and applications. I
                have a strong dedication to delivering high-quality results and
                solving complex issues. Whether you're a business looking for a
                web presence or an employer seeking a skilled developer, feel
                free to get in touch with me.
              </p>
            </div>
          </div>
          <div className="skils-text">
            <div>
              <h4>PROGRAMMATION</h4>
              <p>Html, CSS, JavaScript, Node.js.</p>
            </div>

            <div>
              <h4>Databases</h4>
              <p>MongoDB, MySQL.</p>
            </div>

            <div>
              <h4>WEB FRAMEWORKS</h4>
              <p>
                ReactJS, ExpressJS, Tailwind, Material UI, Redux, Bootstrap.
              </p>
            </div>

            <div>
              <h4>Version Control and tools</h4>
              <p>Git, GitHub, Postman, REST API.</p>
            </div>
          </div>
        </article>
        <Footer />
      </>
    );
  }
}

export default AboutMe;
