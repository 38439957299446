import React, { Component } from "react";
import project1 from "../imgs/projects/p1.webp";
import project2 from "../imgs/projects/p2.webp";
import project4 from "../imgs/projects/p4.webp";
import gitHubIcon from "../imgs/github.webp";
import linkIcon from "../imgs/link.webp";

class Portfolio extends Component {
  render() {
    return (
      <article className="Portfolio-box">
        <h1>Portfolio</h1>
        <h2>&lt;projects&gt;</h2>
        <div className="Portfolio">
          <div className="projects" data-aos="fade-down">
            <div className="project-box">
              <img src={project1} alt="Summize AI Project" />

              <div className="project-text">
                <h3>Summize AI</h3>
                <p>
                  It is an advanced full-stack AI application that simplifies
                  the summarization process. With just a click, it summarizes
                  any article with just a URL, PDF documents, word, and even
                  power points.
                </p>
                <p className="tech">Tech Used :</p>
                <div className="tech-used">
                  <div className="tech-tag">React</div>
                  <div className="tech-tag">Material-UI</div>
                  <div className="tech-tag">Tailwind</div>
                  <div className="tech-tag">Redux-Toolkit</div>
                  <div className="tech-tag">NodeJs</div>
                  <div className="tech-tag">ExpressJs</div>
                  <div className="tech-tag">Web Scraping</div>
                </div>
              </div>
            </div>
          </div>
          <div className="projects " data-aos="fade-down">
            <div className="project-box">
              <a href="https://portfolio-youtube-clone.netlify.app/">
                <img src={project2} alt="youtube-clone" />
              </a>
              <div className="project-text">
                <h3>Youtube-2.0</h3>
                <p>
                  The YouTube Clone is a responsive web application that
                  replicates some of the core features and functionalities of
                  the popular video-sharing platform, YouTube.
                </p>
                <p className="tech">Tech Used :</p>
                <div className="tech-used">
                  <div className="tech-tag">React</div>
                  <div className="tech-tag">Css</div>
                  <div className="tech-tag">Material-UI</div>
                  <div className="tech-tag">RapidApi</div>
                </div>
                <figure className="test">
                  <a href="https://portfolio-youtube-clone.netlify.app/">
                    <img src={linkIcon} className="test-icon" alt="Demo Site" />
                  </a>
                  <a href="https://github.com/Devai-coding/youtube-2.0">
                    <img
                      src={gitHubIcon}
                      className="test-icon"
                      alt="youtube clone github repo"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="projects last-project" data-aos="fade-down">
            <div className="project-box">
              <a href="https://github.com/Devai-coding/library-managment-webApp">
                <img src={project4} alt="library-managment-app" />
              </a>
              <div className="project-text">
                <h3>Library Manager</h3>
                <p>
                  Our Library Manager app enhances the book-borrowing process,
                  providing a user-friendly platform. It offers features for
                  seamless book discovery and borrowing for users, along with
                  effective tools for librarians to manage inventories and user
                  details.
                </p>
                <p className="tech">Tech Used :</p>
                <div className="tech-used">
                  <div className="tech-tag">Html</div>
                  <div className="tech-tag">Css</div>
                  <div className="tech-tag">Php</div>
                  <div className="tech-tag">Ajax</div>
                </div>
                <figure className="test">
                  <a href="https://github.com/Devai-coding/library-managment-webApp">
                    <img
                      src={gitHubIcon}
                      className="test-icon"
                      alt="github repo"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <h2>&lt;/projects&gt;</h2>
      </article>
    );
  }
}

export default Portfolio;
