import "./App.css";
import React, { useEffect } from "react";
import "./queries.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "./components/Container";
import Skills from "./components/Skills";
import Portfolio from "./components/Portfolio";
import TimeLine from "./components/TimeLine";
import AboutMe from "./components/AboutMe";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <>
      <Container />
      <Skills />
      <Portfolio />
      <TimeLine />
      <AboutMe />
      {/* <Testimonials /> */}
    </>
  );
}

export default App;
