import React from "react";

const TimeLine = () => {
  return (
    <div className="my-journey" data-aos="fade-down">
      <h1 className="timeline-header">My journey</h1>
      <div className="timeline">
        <div className="checkpoint">
          <div>
            <h2>
              2ème année Cycle d'ingénieur - génie informatique{" "}
              <span>(en cours)</span>
            </h2>
            <h3>2023-2024</h3>
            <p>ENSA Agadir</p>
          </div>
        </div>
        <div className="checkpoint">
          <div>
            <h2>1ère année Cycle d'ingénieur - génie informatique</h2>
            <h3>2022-2023</h3>
            <p>ENSA Agadir</p>
          </div>
        </div>
        <div className="checkpoint">
          <div>
            <h2>Cycle préparatoire intégré</h2>
            <h3>2020-2022</h3>
            <p>ENSA Agadir </p>
          </div>
        </div>
        <div className="checkpoint">
          <div>
            <h2>BACCALAURÉAT - Sciences Mathématiques</h2>
            <h3>Juin 2020</h3>
            <p>Lycée AL MASSIRA AL KHADRA à Tiznit.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
