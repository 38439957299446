import React, { Component } from "react";
import logo from "../imgs/logo.webp";
import $ from "jquery";
import { FaBars, FaTimes } from "react-icons/fa";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleScrollToElement = (className) => {
    const element = $(`.${className}`);
    if (element.length) {
      $("html, body").animate(
        {
          scrollTop: element.offset().top,
        },
        1000
      );
    }
  };

  handleNavBar = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { isOpen } = this.state;

    return (
      <header>
        <ul>
          <a href="/">
            <img src={logo} className="logo" alt="Logo" />
          </a>
          <nav className={isOpen ? "nav-open" : ""}>
            <li>
              <a onClick={() => this.handleScrollToElement("about-me")}>
                About me
              </a>
            </li>
            <li>
              <a href="#" onClick={() => this.handleScrollToElement("skills")}>
                Skills
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => this.handleScrollToElement("Portfolio")}
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => this.handleScrollToElement("my-journey")}
              >
                My journey
              </a>
            </li>
          </nav>
          <button
            className={`close-btn ${isOpen ? "show" : ""}`}
            onClick={this.handleNavBar}
          >
            <FaTimes />
          </button>
          <button
            className={`open-btn ${isOpen ? "" : "show"}`}
            onClick={this.handleNavBar}
          >
            <FaBars />
          </button>
        </ul>
      </header>
    );
  }
}

export default Header;
