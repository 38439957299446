import React, { Component } from "react";
import circleImage from "../imgs/background shapes/circle_1.png";
import mainImage from "../imgs/main.webp";
import linkdinIcon from "../imgs/linkedin.webp";
import gitHubIcon from "../imgs/github.webp";
import cvPdf from "../utils/cv.pdf"; // Replace with the actual path to your CV PDF file

class Main extends Component {
  handleDownloadCV = () => {
    // Create a virtual link element
    const link = document.createElement("a");
    link.href = cvPdf; // Set the href attribute to your CV PDF file
    link.download = "idboussadel_Cv.pdf"; // Set the desired name for the downloaded file
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link element from the DOM
    document.body.removeChild(link);
  };

  render() {
    return (
      <main data-aos="fade-down">
        <img src={circleImage} alt="cercle" />
        <article className="hero">
          <div className="hero-text">
            <h1 className="animated-text">
              Hi, I'm Abdessamad
              <br />
              Idboussadel.
            </h1>
            <p>
              A passionate web developer and turning <br />
              <b>ideas into stunning websites is my calling</b>.
            </p>
            <button
              type="submit"
              className="button"
              onClick={this.handleDownloadCV}
            >
              Download CV
            </button>
            <div className="socials">
              <a
                href="https://www.linkedin.com/in/abdessamad-idboussadel-52562827b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socials-card">
                  <img src={linkdinIcon} alt="LinkedIn icon" />
                  <p>LinkedIn</p>
                </div>
              </a>
              <a
                href="https://github.com/Devai-coding?tab=repositories"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socials-card">
                  <img src={gitHubIcon} alt="GitHub icon" />
                  <p>GitHub</p>
                </div>
              </a>
            </div>
          </div>
          <div className="hero-image">
            <img src={mainImage} className="main-image" alt="Main" />
          </div>
        </article>
      </main>
    );
  }
}

export default Main;
