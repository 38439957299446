import React, { Component } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import emailIcon from "../imgs/email.webp";

class Footer extends Component {
  componentDidMount() {
    AOS.init(); // Initialize AOS library
  }

  render() {
    return (
      <footer className="footer">
        <div className="email">
          <img src={emailIcon} alt="email icon" />
          <p>idbouid@gmail.com</p>
        </div>
        <p>&copy; 2023 Abdessamad Idboussadel. All rights reserved.</p>
      </footer>
    );
  }
}

export default Footer;
